html,
body {
  padding: 0;
  margin: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

a {
  color: black;
  text-decoration: none;
  font-weight: bold;
}

.Header {
  text-align: center;
  background: black;
  color: white;
  font-weight: bold;
  font-size: 1.2rem;
  padding: 8px;
}

.Form {
  margin: 16px;
}

.Group {
  margin-bottom: 24px;
}

.Group .Title {
  font-size: 1.5rem;
}

label,
input {
  font-size: 1.5rem;
}

hr {
  margin: 0;
}

.Trip {
  padding: 16px;
  border-bottom: 1px solid #ccc;
}

.Trip-Row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.Trip-Stop {
  display: flex;
  flex-direction: column;
}

.Trip-Stop-Time {
  font-size: 1.1rem;
  color: rgba(0, 0, 0, 0.5);
  font-weight: bold;
}
.Trip-Stop-Place {
  font-size: 0.8rem;
  opacity: 0.5;
}
.Trip-Stop.Arrival {
  align-items: flex-end;
  text-align: right;
}
.Trip-Stop.Middle {
  align-items: center;
  text-align: center;
}

.Trip-Transport {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Trip-Transport-Icon {
  font-size: 1.1rem;
  color: rgba(0, 0, 0, 0.5);
  font-weight: bold;
}
.Trip-Transport-Type {
  font-size: 0.8rem;
  font-weight: bold;
}
.Trip-OnDemand {
  font-size: 0.75rem;
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.5);
}
