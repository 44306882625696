html, body {
  margin: 0;
  padding: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
}

a {
  color: #000;
  font-weight: bold;
  text-decoration: none;
}

.Header {
  text-align: center;
  color: #fff;
  background: #000;
  padding: 8px;
  font-size: 1.2rem;
  font-weight: bold;
}

.Form {
  margin: 16px;
}

.Group {
  margin-bottom: 24px;
}

.Group .Title, label, input {
  font-size: 1.5rem;
}

hr {
  margin: 0;
}

.Trip {
  border-bottom: 1px solid #ccc;
  padding: 16px;
}

.Trip-Row {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.Trip-Stop {
  flex-direction: column;
  display: flex;
}

.Trip-Stop-Time {
  color: #00000080;
  font-size: 1.1rem;
  font-weight: bold;
}

.Trip-Stop-Place {
  opacity: .5;
  font-size: .8rem;
}

.Trip-Stop.Arrival {
  text-align: right;
  align-items: flex-end;
}

.Trip-Stop.Middle {
  text-align: center;
  align-items: center;
}

.Trip-Transport {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.Trip-Transport-Icon {
  color: #00000080;
  font-size: 1.1rem;
  font-weight: bold;
}

.Trip-Transport-Type {
  font-size: .8rem;
  font-weight: bold;
}

.Trip-OnDemand {
  color: #00000080;
  margin-top: 8px;
  font-size: .75rem;
}

/*# sourceMappingURL=index.05ae0492.css.map */
